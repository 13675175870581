<template>
  <div class="row d-flex align-items-center mx-5 support-system-background">
    <div class="col">
      <div class="">
        <div class="row">
          <div class="col-12 flex-row justify-content-center d-lg-flex">
            <img src="./assets/logo.png" alt="Yellow Cherry logo" height="250"/>
          </div>
        </div>

        <div class="row">
          <div class="col-12 d-lg-flex flex-column align-items-center justify-content-center">
            <h1>Yellow Cherry Support System</h1>

          </div>
        </div>

        <div class="row">
          <div class="col-10 offset-1">
            <p>
              Having a problem with your website? Trouble accessing email? Require assistance with billing? Or maybe you just want to ask a question! We’re always happy to help with any request. No problem is too big or small for our experienced team. And if we don’t know the answer, we’ll know someone who does!
            </p>
            <p>
              If you have a support request, open a support ticket below using our form so the appropriate team member can get back to you in a timely manner.
            </p>
          </div>
        </div>

        <div class="row d-none">
          <div class="col-10 offset-1 d-flex flex-row justify-content-around">
            <button type="button" class="button my-3 createTicketButton"  @click="toggleTicketDiv">I want to create a ticket</button>
            <button type="button" class="button my-3 existingTicketButton" @click="toggleTicketDiv">I have an existing ticket</button>
          </div>


        </div>
      </div>

    </div>
    <div class="col">
      <div class="row">
        <div class="offset-3 col-6">
          <transition>
            <div class="showCreateTicket" v-if="showCreateTicket">
              <div>
                <CreateTicket @changeEvent="toggleTicketDiv"/>
              </div>

            </div>
          </transition>

          <transition>
            <div class="showExistingTicket" v-if="showExistingTicket">
              <div>
                <LoginForm @changeEvent="toggleTicketDiv"/>
              </div>

            </div>
          </transition>

        </div>
      </div>
    </div>
  </div>







</template>

<script>
import CreateTicket from "@/components/CreateTicket";
import LoginForm from "@/components/LoginForm";

export default {
  name: 'App',
  components: {
    LoginForm,
    CreateTicket,
  },
  methods: {
    toggleTicketDiv(buttonName){
      console.log("Toggling");
      let that = this;

      if(buttonName == "loginButton"){
        this.showExistingTicket = false;

        setTimeout(function(){
          that.showCreateTicket = true;
        }, 1500);

      }else{
        this.showCreateTicket = false;
        setTimeout(function(){
          that.showExistingTicket = true;
        }, 1500);

      }
    }

  },
  data() {
    return {
      showExistingTicket: true,
      showCreateTicket: false,
    }
  }
}
</script>

<style>
@font-face{
  font-family: "nevermind";
  src: local("nevermind"), url("./assets/5362Nevermind.woff2") format("truetype");
}

.v-enter-active {


  transition: all 0.3s ease-out;

}

.v-leave-active {

  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.v-enter-from,
.v-leave-to {
  transform: translateX(20px);
  transition-delay: 1s;
  opacity: 0;
}

.button{
  align-items: center;
  background-attachment: scroll;
  background-clip: border-box;
  background-color: rgb(234, 108, 195);
  background-image: linear-gradient(to right, rgb(234, 108, 195) 0%, rgb(255, 157, 6) 50%, rgb(234, 108, 195) 100%);
  background-origin: padding-box;
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-size: 200% 100%;
  border-bottom-color: rgb(255, 255, 255);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-style: none;
  border-bottom-width: 0px;
  border-image-outset: 0;
  border-image-repeat: stretch;
  border-image-slice: 100%;
  border-image-source: none;
  border-image-width: 1;
  border-left-color: rgb(255, 255, 255);
  border-left-style: none;
  border-left-width: 0px;
  border-right-color: rgb(255, 255, 255);
  border-right-style: none;
  border-right-width: 0px;
  border-top-color: rgb(255, 255, 255);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-top-style: none;
  border-top-width: 0px;
  box-sizing: border-box;
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: inline-block;
  font-family: "nevermind";
  font-feature-settings: normal;
  font-kerning: auto;
  font-language-override: normal;
  font-optical-sizing: auto;
  font-size: 14px;
  font-size-adjust: none;
  font-stretch: 100%;
  font-style: normal;
  font-variant-alternates: normal;
  font-variant-caps: normal;
  font-variant-east-asian: normal;
  font-variant-ligatures: normal;
  font-variant-numeric: normal;
  font-variant-position: normal;
  font-variation-settings: normal;
  font-weight: 400;
  height: 44px;
  letter-spacing: normal;
  line-height: 14px;
  max-width: 100%;
  outline-color: rgb(255, 255, 255);
  outline-style: none;
  outline-width: 0px;
  overflow-x: visible;
  overflow-y: visible;
  padding-bottom: 14px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 14px;
  text-align: center;
  text-transform: capitalize;
  touch-action: manipulation;
  transition-delay: 0s;
  transition-duration: 0.2s;
  transition-property: all;
  transition-timing-function: ease-in-out;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  word-spacing: 0px
}

body{
  background-color: black;
}
html, #app, body{
  height: 100%;
}

*{
  color: white;
}
.support-system-background{
  min-height: 100%;

}

h1, h2, h3{
  background-attachment: scroll;
  background-clip: text;
  background-color: rgba(0, 0, 0, 0);
  background-image: linear-gradient(-135deg, rgb(255, 157, 6), rgb(234, 108, 195));
  background-origin: padding-box;
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-size: auto;
  box-sizing: border-box;
  color: rgb(31, 31, 31);
  font-family: nevermind, "BlinkMacSystemFont", -apple-system, "Roboto", "Lucida Sans";
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -1.5px;
  line-height: 65px;
  margin-bottom: 10px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  text-rendering: optimizelegibility;
  word-spacing: 0.016px;
  -webkit-text-fill-color: rgba(0, 0, 0, 0)
}
</style>
