<template>
  <div class="row">
    <div class="col-12">
      <h2>Create a ticket</h2>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <form method="POST" class="form-group">
        <strong>Name</strong>
        <input type="text" required name="openerName" id="openerName" class="form-control"/>
        <strong>Business Name</strong>
        <input type="text" required name="openerBusinessName" id="openerBusinessName" class="form-control"/>
        <strong>Type of Request</strong>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="typeOfRequest" value="techicalSupport" id="techicalSupport">
          <label class="form-check-label" for="techicalSupport">
            Technical Support
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="typeOfRequest" value="billing" id="billing">
          <label class="form-check-label" for="billing">
            Billing
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="typeOfRequest" value="newBusiness" id="newBusiness">
          <label class="form-check-label" for="newBusiness">
            New Business
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="typeOfRequest" value="urgent" id="urgent">
          <label class="form-check-label" for="urgent">
            Urgent
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="typeOfRequest" value="other" id="other">
          <label class="form-check-label" for="other">
            Other
          </label>
        </div>

        <strong>Subject</strong>
        <input type="text" required name="ticketSubject" id="ticketSubject" class="form-control"/>
        <strong>Description</strong>
        <textarea name="ticketDesc" class="form-control" style="resize: none" rows="4"></textarea>
        <small>Please enter the details of your request. A member of our support staff will respond as soon as possible.</small>
        <div class="row">
          <div class="col">

          </div>
        </div>
        <strong>Upload Files</strong>
        <input type="file" class="form-control" multiple name="ticketAttachments"/>
        <div class="flex d-flex justify-content-between">
          <input type="button" value="Create Ticket" class="button my-3" @click="createThisTicket"/>
          <button type="button" class="button my-3 existingTicketButton" @click="this.$emit('changeEvent', 'existingButton')">I have an existing ticket</button>
        </div>


      </form>
    </div>
  </div>

</template>

<script>
export default {
  name: "CreateTicket",
  methods:{
    createThisTicket(){

    }
  }
}
</script>

<style scoped>

</style>