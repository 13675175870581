<template>
  <div class="row">
    <div class="col-12">
      <h2>View existing tickets</h2>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <form method="POST" class="form-group">
        <strong>Username/Email</strong>
        <input type="text" class="form-control" name="username"/>
        <strong>Password</strong>
        <input type="password" class="form-control" name="password"/>
        <div class="flex d-flex justify-content-between">
          <input type="button" value="Login" class="button my-3" @click="loginToSupportSystem"/>
          <button type="button" class="button my-3 createTicketButton"  @click="this.$emit('changeEvent', 'loginButton')">I want to create a ticket</button>
        </div>

      </form>
    </div>

  </div>
</template>

<script>
export default {
  name: "LoginForm",
  methods: {
    loginToSupportSystem(){

    }
  }
}
</script>

<style scoped>

</style>